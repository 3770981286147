<template>
  <div>
    <v-list v-if="rows.length > 0" subheader two-line dense>
      <template v-for="(item, idx) in rows">
        <v-list-item :key="idx">
          <v-list-item-action>
            <v-icon color="success">mdi-check-all</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-toolbar-title>{{ item.Name }}</v-toolbar-title>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
                icon
                :to="{ name: 'birlesik-form-karnesi', params: { id: item.Id } }"
                v-b-tooltip.hover.top="'Karne'"
            >
              <v-icon>mdi-file-document-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="item.Id" class="mx-2"></v-divider>
      </template>
    </v-list>
    <v-alert class="mt-5" type="warning" v-else>
      Yayınlanmış form karnesi bulunmamaktadır.
    </v-alert>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  name: 'FormAssessmentGroups',
  data() {
    return {
      rows: [],
      loading: false
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/FormAssessmentGroup', {
        sortBy: ['Id'],
        descending: [true],
        rowsPerPage: -1,
        page: 1
      })
          .then((data) => {
            this.rows = data.data.Results
            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    }
  }
}
</script>
