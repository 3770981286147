<template>
  <div>
    <v-list v-if="results.length > 0" subheader two-line dense>
      <template v-for="(item, idx) in results">
        <v-list-item :key="idx">
          <!--          <v-list-item-icon class="mt-4 mr-2">-->
          <!--            <v-icon color="success" small>mdi-check-all</v-icon>-->
          <!--          </v-list-item-icon>-->
          <v-list-item-content>
            <v-list-item-title>
              <v-toolbar-title>{{ item.Name }}</v-toolbar-title>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-icon x-small>mdi-calendar</v-icon>
              {{ item.Date }}
              <v-chip class="ml-1" x-small label color="info" v-if="$vuetify.breakpoint.mobile">
                Form Sonucu: {{ item.Result }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="!$vuetify.breakpoint.mobile">
            <v-chip color="info" label>Form Sonucu: {{ item.Result }}</v-chip>
          </v-list-item-action>
          <v-list-item-action v-if="item.PointTemplateId == 7">
            <v-btn
                icon
                :to="{ name: 'form-karnesi', params: { id: item.FormId } }"
                v-b-tooltip.hover.top="'Karne'"
            >
              <v-icon>mdi-file-document-outline</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-else>
            <v-btn
                icon
                v-b-tooltip.hover.top="'Karne'"
                :to="{ name: 'genel-form-karnesi', params: { id: item.FormId } }"
            >
              <v-icon>mdi-file-document-check</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="item.Id" class="mx-2"></v-divider>
      </template>
    </v-list>
    <v-alert class="m-5" type="warning" v-else> Yayınlanmış form sonucu bulunmamaktadır.</v-alert>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import moment from 'moment'

export default {
  name: 'FormResultsList',
  data() {
    return {
      formUsers: [],
      pointTemplates: [],
      results: []
    }
  },
  created() {
    this.getPointTemplates()
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Form/FormUser', {
        sortBy: ['Id'],
        descending: [true],
        rowsPerPage: -1,
        page: 1,
        query: {
          IsStudent: true,
          HasResult: true
        }
      })
          .then((data) => {
            this.formUsers = data.data.Results
            this.calcResults(this.formUsers)
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getPointTemplates() {
      ApiService.setHeader()
      ApiService.post('api/PointTemplate', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
          .then((data) => {
            this.pointTemplates = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    calcResults(formUsers) {
      for (let idx = 0; idx < formUsers.length; idx++) {
        const formUser = formUsers[idx]
        var formResult = {
          Name: null,
          Date: null,
          Result: null,
          FormId: 0,
          PointTemplateId: 0
        }

        formResult.FormId = formUser.FormId
        formResult.Name = formUser.Form.Name
        formResult.PointTemplateId = formUser.Form.PointTemplateId
        formResult.Date = moment(formUser.CreatedDate).locale('tr').format('Do MMMM YYYY')

        let point = formUser.AssessmentAreaPoint ? formUser.AssessmentAreaPoint : formUser.Point
        let templateName = ''

        if (
            formUser.Form.PointTemplateId == null ||
            !this.pointTemplates.find((s) => s.Id == formUser.Form.PointTemplateId)
        ) {
          formResult.Result = point
        } else {
          var template = this.pointTemplates.find((s) => s.Id == formUser.Form.PointTemplateId)

          template.PointTemplateSections.sort(function (a, b) {
            return a.MinValue - b.MinValue
          })

          template.PointTemplateSections.map((d) => {
            if (point >= d.MinValue) templateName = d.Name
          })

          if (templateName.length > 0)
            formResult.Result = templateName
          else
            formResult.Result = point
        }

        this.results.push(formResult)
      }
    }
  }
}
</script>
