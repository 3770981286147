<template>
    <v-container fluid>
        <v-row>
            <!--            <v-col class="pl-0">-->
            <!--                <c-form-type v-model="filters.formTypeId" @change="getDataFromApi" style="max-width: 200px"></c-form-type>-->
            <!--            </v-col>-->
            <!--            <v-col class="d-flex justify-content-end pr-0">-->
            <!--                <v-select v-model="options.sortBy[0]" dense hide-details outlined :items="sortOptions" @change="getDataFromApi" label="Sırala"-->
            <!--                          style="max-width: 200px"></v-select>-->
            <!--            </v-col>-->
            <v-col>
                <v-text-field v-model="filters.search" label="Form Arama" style="max-width: 350px" outlined dense hide-details append-outer-icon="search"
                              @click:append-outer="getDataFromApi" clearable @change="getDataFromApi" @keydown.enter="getDataFromApi"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="grupedForms.length > 0" class="px-0">
                <v-sheet v-for="(element) in grupedForms" :key="element.key" outlined rounded class="mb-2">
                    <v-card-title class="text-center">{{ element.key }}</v-card-title>
                    <v-list subheader two-line>
                        <template v-for="item in element.values">
                            <v-list-item :key="item.Id">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.Name }}
                                        <span v-show="item.IsAnsweredByUser">
                                    <v-chip class="ml-1" x-small color="success" dark><v-icon x-small left>mdi-check</v-icon>Cevaplandı</v-chip>
                                  </span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="item.CourseName && item.CourseName.length > 0">
                                        Ders: {{ item.CourseName }}
                                    </v-list-item-subtitle>
                                    <div class="text-gray-800 text-hover-primary fw-bolder fs-6 mt-1">
                                        Başlangıç: {{ item.StartDate | moment }}
                                    </div>
                                    <div class="text-gray-800 text-hover-primary fw-bolder fs-6 mt-1">
                                        Bitiş: {{ item.EndDate | moment }}
                                    </div>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon>
                                        <v-icon class="ml-1" color="success" @click="updateForm(item)">fas fa-edit</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider v-show="element.values.at(-1) !== item" :key="item.Id" class="mx-4"></v-divider>
                        </template>
                    </v-list>
                </v-sheet>
            </v-col>
            <v-col v-else>
                <v-alert class="mt-5" type="warning"> Katılabileceğiniz form bulunmamaktadır.</v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ApiService from '@/core/services/api.service'
import moment from 'moment'

export default {
  name: 'FormList',
  data() {
    return {
      forms: [],
      grupedForms: [],
      options: {sortBy: ['StartDate']},
      filters: {formTypeId: null, search: ''},
      loading: false,
      sortOptions: [
        {text: 'Başlangıç Tarihi', value: 'StartDate'},
        {text: 'Bitiş Tarihi', value: 'EndDate'},
        {text: 'Ders Adı', value: 'CourseName'},
        {text: 'Form Adı', value: 'Name'},
        // {text: 'Form Tipi', value: 'FormTypeId'}
      ]
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM YYYY HH:mm') : ''
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Form', {
        sortBy: this.options.sortBy,
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        searchVal: this.filters.search,
        query: this.filters
      })
        .then((data) => {
          this.forms = data.data.Results
          this.grupedForms = this.groupByArray(this.forms, 'FormTypeName')
          this.loading = false
        })
        .catch(({response}) => {
          ApiService.showError(response)
          this.loading = false
        })
    },

    updateForm(item) {
      this.$emit('changeTabEvent', item)
    },

    groupByArray(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({key: v, values: [x]})
        }
        return rv
      }, [])
    },
  },
}
</script>
