<template>
  <v-card min-height="800px">
    <v-tabs grow centered show-arrows v-model="tab" background-color="primary" dark>
      <v-tab href="#tab-1"> SONUÇLANAN FORMLAR</v-tab>
      <v-tab href="#tab-2"> AKTİF FORMLAR</v-tab>
      <v-tab href="#tab-4"> FORM KARNELERİ</v-tab>
      <v-tab class="d-none" href="#tab-3"> update form</v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="tab">
      <v-tab-item :value="'tab-1'">
        <v-card flat>
            <FormResultsList class="mt-2"></FormResultsList>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-2'">
        <v-card flat>
          <v-card-text>
            <FormList @changeTabEvent="changeTab($event)"></FormList>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-3'">
        <v-card flat>
          <v-card-text>
            <FormQuestionsPage
                v-if="formId > 0"
                :formId="formId"
                @close="returnList($event)"
                :key="componentKey"
            ></FormQuestionsPage>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-4'">
        <v-card flat>
          <v-card-text>
            <FormAssessmentGroups></FormAssessmentGroups>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import FormList from '@/view/pages/form/FormList'
import FormQuestionsPage from '@/view/pages/form/FormApp/FormQuestionsPage'
import FormResultsList from '@/view/pages/form/FormResultsList'
import FormAssessmentGroups from '@/view/pages/form/FormAssessmentGroups.vue'

export default {
  components: {FormAssessmentGroups, FormList, FormQuestionsPage, FormResultsList},
  name: 'form-list',
  data() {
    return {
      tab: null,
      componentKey: 0,
      formId: 0
    }
  },
  methods: {
    changeTab(item) {
      this.formId = item.Id
      this.tab = 'tab-3'
      this.componentKey += 1
    },
    returnList() {
      this.formId = 0
      this.tab = 'tab-1'
    }
  }
}
</script>
